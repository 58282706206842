.tittle_Preview {
  align-items: flex-start;

  .tittle_Preview_name {
    p {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #6f6f6f;
    }
  }
  .tittle_Preview_icon {
    i {
      font-size: 2rem;
      font-weight: 500;
      color: #c4c4c4;
    }
  }
}

.address-preview_Background {
  background-color: #fff;
  border-radius: 25px;
  padding: 24px;
  margin-right: 0px !important;
  margin-left: 40px !important;
}

.address_input_coordinates {
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #f0f0f0;
}

.input_box {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  margin-bottom: 16px;
  margin-left: 0px;
  margin-right: 0px;

  .input_box_title {
    background-color: white;
    padding: 11px;
    border-radius: 15px;

    h6 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: $site-primary-color;
      margin-bottom: 0px;
    }
  }

  input {
    width: 100%;
    padding: 10px;
    border-color: transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $site-primary-color;
  }

  input::placeholder {
    color: #c4c4c4;
  }

  input:focus-visible {
    outline: transparent;
  }
}

.address_confirmButton {
  width: 100%;
  background-color: $site-primary-color;
  border-radius: 10px;
  padding: 10px;
  border: transparent;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  margin-bottom: 16px;
  color: #ffffff;
}

.address_closeButton {
  width: 100%;
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  padding: 9px;
  border: 1px solid $site-primary-color;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: $site-primary-color;
}
