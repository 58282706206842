.alert-bloq {
  .alert-container {
    width: 100%;
    height: 80px;
    border-radius: 20px !important;

    .alert-text {
      height: 100%;
      padding: 7px 15px;
      display: flex;
      align-items: center;

      .alert-font {
        font-style: normal;
        font-weight: normal;
        font-size: 19px;
        line-height: 21px;
        color: #ffffff;
        vertical-align: middle;
      }
    }
  }

  .default-background {
    background: linear-gradient(
      83.15deg,
      #c62440 -7.64%,
      #803460 29.44%,
      #2f4784 65.93%,
      #246c74 105.36%
    );
  }
}
