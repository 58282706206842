.container-full {
  background-color: #fff;
  border-radius: 25px;
  padding: 24px;
  height: 100%;
  margin-left: 40px;

  .container-title {
    align-items: center;
    margin-bottom: 30px;
    i {
      font-size: 2rem;
      font-weight: 500;
      color: #c4c4c4;
    }

    .col-title {
      align-items: center;
      display: flex;
      .title {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        display: flex;
        align-items: center;
        color: #6f6f6f;
        margin-bottom: 0px;
      }
    }

    .btn-close {
      border-radius: 50%;
      background: #c4c4c4;
      padding: 0px;
      float: right;
      cursor: pointer;

      .icon-close {
        align-content: center;
        vertical-align: middle;
        path {
          fill: white;
        }
      }
    }
  }

  .social-media-logo {
    background: #0a4caa;
    border-radius: 22px;
    width: 28px;
    height: 28px;
    margin-top: 10px;
    display: inline-block;
    margin-left: 10px;
  }

  .container-rrss {
    height: 48px;
    margin-left: 1px;
    margin-right: 1px;
    border: 1px solid #c4c4c4 !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
    padding-left: 4px !important;
    margin-bottom: 16px;

    &__null {
      border: 0px solid #c4c4c4 !important;
      background: #f0f0f0;
    }

    .col-rrss {
      padding: 0px 5px;
      label {
        display: none;
      }
    }

    .md-form {
      margin: 12px 0px;

      .input-rrss {
        border-bottom: 0px !important;
        padding-top: 1px !important;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: #0a4caa;
      }
      .input-rrss::placeholder {
        color: #c4c4c4;
      }
      .input-rrss[type='url']:not(.browser-default):focus:not([readonly]) {
        box-shadow: none;
      }
    }

    .icon-clear {
      margin-top: 12px;
      float: right;
      margin-right: 1px;
      path {
        fill: #c4c4c4;
      }
    }

    .icon-clear-active {
      margin-top: 12px;
      cursor: pointer;
      float: right;
      path {
        fill: #0a4caa;
      }
    }
  }

  .container-btns {
    margin-top: 30px;
    padding: 0px;
    padding-left: 16px;
    position: absolute;
    bottom: 24px;
    width: 84%;

    .col-cancel {
      padding-left: 0px;
      padding-right: 8px;

      .btn_cancelar {
        width: 100%;
        background: #ffffff !important;
        margin: 0px;
        border: 1px solid $site-primary-color;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 9px;

        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-transform: none;

        color: $site-primary-color !important;

        :active {
          background-color: #ffffff !important;
        }
      }

      #btn_cancelar {
        background-color: #ffffff !important;
      }
    }

    .col-save {
      padding-left: 8px;
      padding-right: 0px;

      .btn_save {
        background: $site-primary-color !important;
        border-radius: 10px;
        margin: 0px;
        width: 100%;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        padding: 10px;

        text-transform: none;
        color: #ffffff;
      }

      #btn_save {
        background-color: $site-primary-color !important;
      }
    }
  }
}
