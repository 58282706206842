.col-floating {
  top: 40px;
  position: sticky;
  margin-top: 33px;
  margin-left: 51px !important;
}
.bloqs-col {
  margin-left: 20px;
  padding: 0px 20px;

  .row-bloq {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 24px;
  }

  .bloqs_bg_principal {
    background-color: #ffffff;
    border: 1px solid #cecece;
    border-radius: 10px;
  }

  .arrow_drop {
    margin-top: 2px;
    font-size: 30px;
    color: $site-primary-color;
  }

  .bloqs_bg_header {
    background-color: transparent;
    border-bottom: transparent;
    padding: 8px 1px;
  }

  .bloqs_title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    align-items: center;
    color: #000000;
    margin-bottom: 0px;
  }

  .bloqs_section_title {
    margin-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    align-items: center;
    color: #565656;
  }

  .bloqs_sectionItem_container {
    border-radius: 20px;
    margin: 17px 0px;
    border: 1px solid #c4c4c4;
    div {
      margin: 0px;
    }

    .section-item {
      padding: 10px 11px 10px 18px;
    }

    .no-padding {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }

  .bloqs_sectionItem_secondary_container {
    margin: 17px 0px;
  }

  .bloqs_sectionItem_carousel_container {
    position: relative;
    width: 354px;
    height: 128px;
    text-align: center;
    background: linear-gradient(
      0deg,
      #eeeeee 0%,
      rgba(238, 238, 238, 0) 74.22%
    );
    border: 1px solid #c4c4c4;
    margin: 17px;
    box-sizing: border-box;
    border-radius: 20px;
    svg {
      margin-top: 19px;
      color: #cccccc;
      font-size: 4.5rem;
    }
    span {
      position: absolute;
      bottom: 0px;
      right: 60px;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      align-items: center;
      text-align: center;

      color: #c4c4c4;
    }
  }
  .bloq_carrousel_preview {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .alert-bloq {
    margin-top: 20px;
    margin-bottom: 17px;
  }
}

.section_confirmButton {
  width: 100%;
  background-color: $site-primary-color;
  border-radius: 10px;
  padding: 10px;
  border: transparent;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  margin-bottom: 16px;
  color: #ffffff;
}
.section_confirmButtonDisabled {
  width: 100%;
  background-color: #c2c2c2;
  border-radius: 10px;
  padding: 10px;
  border: transparent;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  margin-bottom: 16px;
  color: #ffffff;
}

.section_closeButton {
  width: 100%;
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  padding: 9px;
  border: 1px solid $site-primary-color;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: $site-primary-color;
}

.no-blocks-message {
  padding: 30px 0px 40px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  align-items: center;
  color: $site-primary-color;
}

.select_block_schedule {
  background: transparent;
  color: #ffffff;
  border: transparent;
  border-radius: 10px;
  padding: 6px 2px 6px 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  border-right: 6px solid transparent;
  option {
    color: #000000;
    background: #ffffff;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
}
.select_block_schedule:focus-visible {
  outline: transparent;
}
.letter_schedule {
  top: 5px;
  color: #ffff;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  position: relative;
  left: 6px;
}

.edit_icon_blue {
  position: absolute;
  right: 9px;
  top: 31px;
  color: #00379b !important;
}
