.home-page {
  height: calc(100vh - 66px);

  @include responsive-mobile {
    padding-left: 0px;
  }

  .h-65 {
    height: 65% !important;
  }

  .home_page_logout {
    position: relative;
    right: 48px;
    top: 48px;
    text-align: end;
    cursor: pointer;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #00379b;
    .home_page_logout_icon_container {
      svg {
        margin-bottom: 2px;
      }
    }
  }

  .home-card {
    width: 226px;
    height: 226px;
    background: #ffffff;
    border: 16px solid #f3f3f3;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    border-radius: 40px;
    margin: auto;
    .home-card-body {
      flex: inherit;
      text-align: center;
      padding: 17px;
    }
    .home_card_title {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      align-items: center;
      text-align: center;
      color: #6f6f6f;
      margin-bottom: 0px;
    }
    hr {
      color: #6f6f6f;
      margin: 10px;
    }
    .home_card_subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      align-items: center;
      text-align: center;
      color: #6f6f6f;
    }
    .home_card_icon {
      margin-bottom: 10px;
      width: 35px;
      height: 35px;
      path {
        stroke: #6f6f6f;
      }
    }
  }

  &__wrapper-breadcrumb {
    color: #224b8c;
    background-color: white;
    border-bottom: 1px solid #e5e5e5;
    padding: 50px 0px 0px 0px;
  }

  &__content {
    padding-top: 10px;
  }

  &__filters {
    width: 50%;
    @include flex(flex-start, center);

    @include responsive-mobile {
      width: 100%;
      flex-direction: column;
      align-items: normal;
    }

    div:not(:last-child) {
      @include responsive-mobile {
        margin: 0px;
        flex-grow: 1 1 100%;
      }

      margin-right: 20px;
    }
  }

  &__wrapper-total-bar {
    padding: 10px 0px;

    .total-bar {
      background-color: $primary;
      color: white;
      padding: 3px 23px;
      border-radius: 10px;
      @include flex(flex-start, center);

      &__item {
        &--right {
          margin-left: auto;
        }

        &:first-child {
          padding-right: 45px;
          margin-right: 18px;
          border-right: 1px solid white;
        }
      }
    }
  }

  &__alerts {
    @include flex-center;
  }

  &__actions {
    @include flex(flex-end, center);
  }
}
