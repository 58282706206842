@import '../settings/global';

$color: $site-primary-color;

.tabLang {
  margin-right: 4px;
  position: relative;
  top: 24px;
  left: 60px;
  background: #c4c4c4;
  border-radius: 12px 12px 0px 0px;
  text-align: center;
  cursor: pointer;
  padding: 6px;
  color: #fff;
  font-size: 18px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
}
.activeTab {
  background: #00379b;
}

.templateHome {
  width: 100%;
  height: 100%;

  .templateHome_background_start {
    background-color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding: 10px;
    padding-bottom: 0px;
    width: 414px;
  }

  .templateHome_background {
    background-color: #fff;
    padding: 0px 10px;
    width: 414px;
  }

  .templateHome_background_end {
    background-color: #fff;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    padding: 10px;
    padding-top: 0px;
    width: 414px;
  }
}
.text_color_primary {
  color: #c4c4c4 !important;
}
