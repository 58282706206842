.section-item,
.sub-bloque-item {
  background: white;
  margin-top: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center !important;
  padding: 11px 11px 11px 18px;
  border-radius: 20px !important;
  vertical-align: middle;

  &__default {
    background: #f0f0f0;
    border-radius: 10px !important;
    margin: 5px 0px !important;
    padding: 10px 18px !important;
  }
  .no-padding {
    padding-left: 4px !important;
    padding-right: 12px !important;
  }

  &__navidad {
    margin-top: 0px !important;
    min-height: 50px;
  }

  .grid-item-text {
    align-self: center;
  }

  .title-item {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    &__default {
      font-size: 16px !important;
      line-height: 19px !important;
      color: black;
    }

    &__blue {
      color: #144b96;
    }
    &__mint {
      color: #246c74;
    }
    &__red {
      color: #e9352c;
    }
    &__green {
      color: #39ac00;
    }
    &__wine {
      color: #743766;
    }
    &__rainbow {
      color: linear-gradient(88.04deg, #e21e34 1.54%, #246c73 98.35%);
    }
    &__navidad {
      color: white;
      text-shadow: 2px 0 0 #e12727, -2px 0 0 #e12727, 0 2px 0 #e12727,
        0 -2px 0 #e12727, 2px 2px #e12727, -2px -2px 0 #e12727,
        2px -2px 0 #e12727, -2px 2px 0 #e12727;
      font-weight: normal;
      min-height: 50px;
      display: flex;
      align-items: center;
    }
  }

  .icon-position {
    align-items: center;
  }

  .half {
    font-size: 16px !important;
    width: 90%;
    padding: 0px 0px 0px 3px;
  }

  .description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: flex-end;
    color: #6e6e6e;
  }

  .item-icon {
    position: relative;
    display: flex;
    width: 50px !important;
    height: 50px !important;
    float: right;
    border-radius: 12px;

    &__default {
      padding: 8px 0px;
      width: 40px !important;
    }

    &__blue {
      background: linear-gradient(180deg, #144b96 0%, #1b67cf 100%);
    }
    &__mint {
      background: linear-gradient(180deg, #05565f 0%, #246c74 100%);
    }
    &__green {
      background: linear-gradient(180deg, #39ac00 0%, #43ca00 100%);
    }
    &__red {
      background: linear-gradient(180deg, #e9352c 0%, #ff6c65 100%);
    }
    &__wine {
      background: linear-gradient(180deg, #743766 0%, #7d4b72 100%);
    }
    &__navidad {
      background: white;
    }
    &__rainbow {
      background: linear-gradient(88.04deg, #e21e34 1.54%, #246c73 98.35%);
    }

    .demoIcon {
      display: inline-block;
      font-family: 'parque_arauco_v2';
      font-size: 1.9em !important;
      font-style: normal;
      font-feature-settings: normal;
      font-variant: normal;
      line-height: 1em;
      margin-top: 1px;
      text-align: center;
      text-decoration: inherit;
      text-transform: none;
      width: 1em;
      margin: 10px;
      color: white;

      &__black {
        color: #181713 !important;
        margin: 0px !important;
        font-size: 56px !important;
      }
    }

    .demoIconPlus {
      display: inline-flex;
      font-family: 'arauco_plus';
      font-size: 1.9em !important;
      font-style: normal;
      font-feature-settings: normal;
      font-variant: normal;
      line-height: 1em;
      margin-top: 1px;
      text-align: center;
      text-decoration: inherit;
      text-transform: none;
      width: auto;
      margin: 0px auto;
      color: white;
      align-items: center;

      &__black {
        color: #181713 !important;
        font-size: 40px !important;
        vertical-align: middle;
      }

      &__navidad {
        display: inline;
        background: linear-gradient(
          180deg,
          #e12727 0%,
          #509c36 100%
        ) !important;
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
        font-size: 42px !important;
        margin-top: 9px;
      }
    }
  }

  .half-icon {
    width: 50px !important;
    height: 50px !important;
    margin-top: 1px;
  }
}
