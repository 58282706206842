.modal_content_edit {
  z-index: 99;
  border-radius: 23px !important;
  padding: 24px;
  height: 699px;
  width: 414px;
}

.modal_section_title_box {
  margin: 25px 10px 21px 30px;
  max-height: 67px;
  .modal_section_title {
    font-size: 22px;
    font-style: normal;
    font-weight: normal;
    line-height: 32px;
    color: #000000;
  }
  .modal_section_section {
    font-size: 22px;
    font-style: normal;
    font-weight: normal;
    line-height: 32px;
    color: #00379b;
  }
}
.bloqs_modal_sectionItem_container {
  background-color: #f0f0f0;
  height: 114px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 32px;
  .bloqs_modal_sectionItem_outline {
    min-width: 354px;
    border-radius: 20px;
    margin: 17px;
    border: 2.8px dashed #c4c4c4;
    div {
      margin: 0px;
    }
  }
}

.bloqs_modal_sectionItem_container_two {
  background-color: #f0f0f0;
  height: 160px;
  justify-content: center;
  align-items: center;
  display: flex;

  .bloqs_modal_sectionItem_outline_two {
    min-width: 354px;
    height: 128px;
    border-radius: 20px;
    margin: 17px;
    img {
      max-width: 354px;
      height: 128px;
      border-radius: 20px;
      object-fit: cover;
      border: 2px dashed #dd1f36;
      box-sizing: border-box;
      border-radius: 20px;
    }
  }
}

.modal_edit_container_inputs {
  .modal_edit_inputs {
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 16px;
    height: 48px;
    padding: 0px 15px;
  }
  .modal_edit_inputs:focus-within {
    background: #ffffff;
    border: 1px solid #c4c4c4;
    outline: transparent;
    box-sizing: border-box;
    border-radius: 10px;
  }
}

.modal_edit_select {
  background: #ffffff !important;
  width: 100%;
  border: 1px solid #c4c4c4 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
  margin: 0px 0px 16px 0px !important;
  height: 48px !important;
  padding: 0px 15px !important;

  modal_edit_select:focus-within {
    background: #ffffff !important;
    border: 1px solid #c4c4c4 !important;
    outline: transparent !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
  }
  input {
    border-bottom: transparent !important;
  }
  span {
    color: $site-primary-color !important;
    font-size: 1rem !important;
    margin-right: 16.5px;
    position: relative;
    div {
      position: absolute;
      right: 0px;
      bottom: 5px;
    }
  }
  label {
    padding: 2px;
    margin-left: 16.5px;
    width: 77% !important;
  }
  ul {
    height: 262px !important;
    li {
      margin: 10px 0px;
    }
  }
}

.modal_edit_switch {
  margin-top: 7px;
}

//============ CAROUSEL STYLES

.modal_edit_carousel_button {
  width: 24px;
  height: 24px;
  background-color: #c4c4c4;
  border-radius: 24px;
  border: transparent;
  color: #fff;
  padding: 0px;
  margin-bottom: 16px;
}
.activeModal {
  background-color: #00379b !important;
}
.modal_edit_carousel_button_add {
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border-radius: 24px;
  border: transparent;
  color: #00379b;
  padding: 0px;
  margin-bottom: 16px;
  svg {
    font-size: 27px;
    margin-top: -1px;
  }
}

.modal_Button_input {
  display: none;
}
.modal_edit_carousel_text {
  margin-top: 16px;
  padding: 0px 64px 0px 32px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #565656;
}

.bloqs_sectionItem_carousel_container_modal {
  position: relative;
  text-align: center;
  background: linear-gradient(0deg, #eeeeee 0%, rgba(238, 238, 238, 0) 74.22%);
  box-sizing: border-box;
  height: 128px;
  border-radius: 20px;
  object-fit: cover;
  border: 2px dashed #dd1f36;
  box-sizing: border-box;
  border-radius: 20px;
  svg {
    margin-top: 19px;
    color: #cccccc;
    font-size: 4.5rem;
  }
  span {
    position: absolute;
    bottom: 0px;
    right: 112px;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    align-items: center;
    text-align: center;

    color: #c4c4c4;
  }
}

.modal_button_add_image {
  width: 100%;
  height: 40px;
  background-color: #0250b6;
  border-radius: 10px;
  padding: 7px;
  border: transparent;
  margin-top: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18.75px;
  align-items: center;
  text-align: center;
  margin-bottom: 16px;
  color: #ffffff;
  label {
    vertical-align: sub;
  }
}

.modal_button_add_alert {
  width: 100%;
  height: 40px;
  background-color: #ffff;
  border-radius: 10px;
  padding: 7px;
  border: 1px solid #00379b;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 16px;
  color: #00379b;
  label {
    vertical-align: sub;
  }
}

// ALERTS

.bloqs_modal_sectionItem_container_alert {
  background-color: #f0f0f0;
  width: 414px;
  height: 112px;
  justify-content: center;
  align-items: center;
  display: flex;

  .bloqs_modal_sectionItem_outline_alert {
    width: 414px;
    height: 112px;
    border-radius: 20px;
    margin: 17px;
    div {
      align-content: center;
    }
    img {
      max-width: 354px;
      height: 128px;
      border-radius: 20px;
      object-fit: cover;
      border: 2px dashed #dd1f36;
      box-sizing: border-box;
      border-radius: 20px;
    }
  }
}
.bloqs_sectionItem_alert_container_modal {
  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
  }
}

.modal_edit_confirm_button {
  width: 100%;
  background-color: #0250b6;
  border-radius: 10px;
  padding: 7px;
  border: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  align-items: center;
  text-align: center;
  margin-bottom: 16px;
  color: #ffffff;
}

.modal_edit_close_button {
  width: 100%;
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  padding: 7px;
  border: 1px solid $site-primary-color;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: $site-primary-color;
}
