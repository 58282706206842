.container-logo {
  margin-left: 50px;
  // text-align: center;
  // max-width: 376px;
  .mall-logo {
    width: 150px;
  }
}

.container-name-mall {
  align-items: center;
  padding: 0px 40px;
  width: 952px;

  .row-name-mall {
    height: 100%;
    text-align: center;
    margin-bottom: 24px;
    padding: 0px 13px;

    h2 {
      margin-bottom: 0px;
    }
  }
}

.newMall_mallName {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 37px;
  color: #565656;
}

.newMall_background {
  background-color: $components-background;
  border-radius: 40px;
  // min-height: calc(100vh - 93px);
  height: -webkit-fill-available;
  height: fit-content;
  padding: 32px;
  margin-top: 24px !important;
  margin-bottom: 24px !important;
  width: 989px;

  .newMall_GeneralInfo {
    padding-right: 0px;
    margin-left: 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
  }

  .newMall_InfoRow {
    div {
      display: inline-flex;
      border-bottom: 2px solid #e7e7e7;
      align-items: center;
      width: 100%;
      padding: 12px 0px;

      .newMall_Title {
        width: 20%;
        font-weight: normal;
        font-style: normal;
        font-size: 18px;
        line-height: 45px;
        color: #000000;
      }
      .newMall_Text {
        margin-bottom: 0px;
        padding: 0px;
        font-weight: bold;
        font-size: 20px;
        color: #6f6f6f;
      }
      .newMall_sections {
        width: 100%;
      }
      .newMall_Button {
        margin-left: auto;
        font-weight: normal;
        font-size: 1rem;
        padding: 5px;
        background-color: black !important;
        border-radius: 7px;
      }
    }
  }

  .newMall_Preview {
    // padding-left: 40px;
    margin-top: -33px;
    margin-bottom: 0px;
  }

  .preview_Background {
    background-color: #fff;
    border-radius: 25px;
    padding: 24px;
    margin-right: 0px;
    margin-left: 40px;
  }

  padding-left: 40px;
  margin-top: -33px;
  margin-bottom: -33px;
}

.newMall_finished {
  font-size: 30px;
  align-items: center;
  padding-top: 10px;

  svg {
    vertical-align: initial;
  }
}
.newMall_checked {
  color: $site-primary-color;
}
.newMall_unchecked {
  color: #d2d2d2;
}
.newMall_activeRow {
  width: 100%;
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px 12px 16px;
}

.newMall_activeRow:hover {
  cursor: pointer;
  background-color: #e3e3e3;
  padding: 12px 16px 12px 16px;
  border-radius: 10px;
}

.active {
  background-color: #fff;
  padding: 12px 16px 12px 16px;
  border-radius: 10px;
  span {
    color: $site-primary-color !important;
    font-weight: bold !important;
  }
}
