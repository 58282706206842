@font-face {
  font-family: 'arauco_plus';
  src: url('../assets/fonts/FontAraucoPlus/font/fontello.eot');
  src: url('../assets/fonts/FontAraucoPlus/font/fontello.eot')
      format('embeddedOpentype'),
    url('../assets/fonts/FontAraucoPlus/font/fontello.woff') format('woff'),
    url('../assets/fonts/FontAraucoPlus/font/fontello.ttf') format('truetype'),
    url('../assets/fonts/FontAraucoPlus/font/fontello.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.demoIconPlus {
  display: inline-block;
  font-family: 'arauco_plus';
  font-size: 2.5em;
  font-style: normal;
  font-variant: normal;
  line-height: 1em;
  margin-top: 1px;
  mozosx-fontsmoothing: grayscale;
  speak: none;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  webkit-fontsmoothing: antialiased;
  width: 1em;
}

.demoIconPlus i {
  font-style: normal;
}

.iconPlus-accesorios_y_bijouterie:before {
  content: '\e800';
} /* '' */
.iconPlus-agencias_de_turismo:before {
  content: '\e801';
} /* '' */
.iconPlus-agencia_de_viajes:before {
  content: '\e802';
} /* '' */
.iconPlus-arauco-foodie:before {
  content: '\e803';
} /* '' */
.iconPlus-arauco-tag:before {
  content: '\e804';
} /* '' */
.iconPlus-articulos_religiosos:before {
  content: '\e805';
} /* '' */
.iconPlus-ascensores:before {
  content: '\e806';
} /* '' */
.iconPlus-automotriz:before {
  content: '\e807';
} /* '' */
.iconPlus-bancos:before {
  content: '\e808';
} /* '' */
.iconPlus-baño_de_hombres:before {
  content: '\e809';
} /* '' */
.iconPlus-baño_de_mujeres:before {
  content: '\e80a';
} /* '' */
.iconPlus-baño_familiar:before {
  content: '\e80b';
} /* '' */
.iconPlus-bazar_gourmet:before {
  content: '\e80c';
} /* '' */
.iconPlus-bebés_y_embarazadas:before {
  content: '\e80d';
} /* '' */
.iconPlus-belleza:before {
  content: '\e80e';
} /* '' */
.iconPlus-bicicleteros:before {
  content: '\e80f';
} /* '' */
.iconPlus-boulevard:before {
  content: '\e810';
} /* '' */
.iconPlus-cajeros:before {
  content: '\e811';
} /* '' */
.iconPlus-calzado_femenino:before {
  content: '\e812';
} /* '' */
.iconPlus-calzado_infantil:before {
  content: '\e813';
} /* '' */
.iconPlus-calzado_masculino:before {
  content: '\e814';
} /* '' */
.iconPlus-cargadores_de_celular:before {
  content: '\e815';
} /* '' */
.iconPlus-carteras:before {
  content: '\e816';
} /* '' */
.iconPlus-chocolate:before {
  content: '\e817';
} /* '' */
.iconPlus-casas_de_cambio:before {
  content: '\e818';
} /* '' */
.iconPlus-click_and_collect:before {
  content: '\e819';
} /* '' */
.iconPlus-coches:before {
  content: '\e81a';
} /* '' */
.iconPlus-comida_americana:before {
  content: '\e81b';
} /* '' */
.iconPlus-comida_asiatica:before {
  content: '\e81c';
} /* '' */
.iconPlus-comida_chilena:before {
  content: '\e81d';
} /* '' */
.iconPlus-comida_internacional:before {
  content: '\e81e';
} /* '' */
.iconPlus-comida_italiana:before {
  content: '\e81f';
} /* '' */
.iconPlus-comida_peruana:before {
  content: '\e820';
} /* '' */
.iconPlus-computacion_y_tecnología:before {
  content: '\e821';
} /* '' */
.iconPlus-deporte_y_outdoors:before {
  content: '\e822';
} /* '' */
.iconPlus-distrito_de_lujo:before {
  content: '\e823';
} /* '' */
.iconPlus-drop_off_and_pick_up_points:before {
  content: '\e824';
} /* '' */
.iconPlus-entretencion:before {
  content: '\e825';
} /* '' */
.iconPlus-electrolineras:before {
  content: '\e826';
} /* '' */
.iconPlus-farmacias:before {
  content: '\e827';
} /* '' */
.iconPlus-fotografia:before {
  content: '\e828';
} /* '' */
.iconPlus-grandes_tiendas:before {
  content: '\e829';
} /* '' */
.iconPlus-heladeria_cafeteria:before {
  content: '\e82a';
} /* '' */
.iconPlus-hogar_deco:before {
  content: '\e82b';
} /* '' */
.iconPlus-icono-ubicación-menu-1:before {
  content: '\e82c';
} /* '' */
.iconPlus-joyería:before {
  content: '\e82d';
} /* '' */
.iconPlus-lavado_de_autos:before {
  content: '\e82e';
} /* '' */
.iconPlus-lenceria:before {
  content: '\e82f';
} /* '' */
.iconPlus-libros_librería:before {
  content: '\e830';
} /* '' */
.iconPlus-lockers:before {
  content: '\e831';
} /* '' */
.iconPlus-manualidades:before {
  content: '\e832';
} /* '' */
.iconPlus-maquinas_dispensadoras:before {
  content: '\e833';
} /* '' */
.iconPlus-mascotas:before {
  content: '\e834';
} /* '' */
.iconPlus-mudadores_de_bebés:before {
  content: '\e835';
} /* '' */
.iconPlus-musica_e_instrumentos:before {
  content: '\e836';
} /* '' */
.iconPlus-noel:before {
  content: '\e837';
} /* '' */
.iconPlus-opinion:before {
  content: '\e838';
} /* '' */
.iconPlus-pago-online:before {
  content: '\e839';
} /* '' */
.iconPlus-panadería_heladería:before {
  content: '\e83a';
} /* '' */
.iconPlus-optica:before {
  content: '\e83b';
} /* '' */
.iconPlus-patente-fyc:before {
  content: '\e83c';
} /* '' */
.iconPlus-patio_de_comida:before {
  content: '\e83d';
} /* '' */
.iconPlus-personal_shopper:before {
  content: '\e83e';
} /* '' */
.iconPlus-ph_ticket-light:before {
  content: '\e83f';
} /* '' */
.iconPlus-piso_diseño:before {
  content: '\e840';
} /* '' */
.iconPlus-primeros_auxilios:before {
  content: '\e841';
} /* '' */
.iconPlus-qr:before {
  content: '\e842';
} /* '' */
.iconPlus-recomendador-salida:before {
  content: '\e843';
} /* '' */
.iconPlus-redbank:before {
  content: '\e844';
} /* '' */
.iconPlus-restaurantes:before {
  content: '\e845';
} /* '' */
.iconPlus-sala_de_lactancia:before {
  content: '\e846';
} /* '' */
.iconPlus-salidas:before {
  content: '\e847';
} /* '' */
.iconPlus-salud:before {
  content: '\e848';
} /* '' */
.iconPlus-servicio_al_cliente-1:before {
  content: '\e849';
} /* '' */
.iconPlus-servicios-1:before {
  content: '\e84a';
} /* '' */
.iconPlus-servicios:before {
  content: '\e84b';
} /* '' */
.iconPlus-silla_de_ruedas:before {
  content: '\e84c';
} /* '' */
.iconPlus-souvenirs:before {
  content: '\e84d';
} /* '' */
.iconPlus-taxis_privados:before {
  content: '\e84e';
} /* '' */
.iconPlus-telefonia_internet_television:before {
  content: '\e84f';
} /* '' */
.iconPlus-tienda_comida_interior:before {
  content: '\e850';
} /* '' */
.iconPlus-tiendas_especializadas:before {
  content: '\e851';
} /* '' */
.iconPlus-turismo:before {
  content: '\e852';
} /* '' */
.iconPlus-user-01:before {
  content: '\e853';
} /* '' */
.iconPlus-vestuario_femenino:before {
  content: '\e854';
} /* '' */
.iconPlus-vestuario_infantil:before {
  content: '\e855';
} /* '' */
.iconPlus-vestuario_juvenil:before {
  content: '\e856';
} /* '' */
.iconPlus-vestuario_masculino:before {
  content: '\e857';
} /* '' */
.iconPlus-vinos:before {
  content: '\e858';
} /* '' */
.iconPlus-warning:before {
  content: '\e859';
} /* '' */
