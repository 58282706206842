

.loading-ap {

  .wrap-backdrop-loading {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: white;
    z-index: 99999998;
    opacity: 0.8;
  }

  .div-mask {
    height: 60px;
    width: 164px;
    position: absolute;
    top: 49%;
    left: calc(50% - 254px);
    z-index: 999999999;
    background-color: transparent;
    animation: mask-play ease-out .2s;
    -webkit-animation: mask-play ease-out .2s;
    -moz-animation: mask-play ease-out .2s;
  }
  @keyframes mask-play {
    0% {
      background-color: white;
      transform: translate(82px);
    }
    100% {
      transform: translate(-2px);
      background-color: white;
    }
  }
  @-webkit-keyframes mask-play {
    0% {
      background-color: white;
      -webkit-transform: translate(82px);
    }
    100% {
      background-color: white;
      -webkit-transform: translate(-2px);
    }
  }
  @-moz-keyframes mask-play {
    0% {
      background-color: white;
      -moz-transform: translate(82px);
    }
    100% {
      background-color: white;
      -moz-transform: translate(-2px);
    }
  }

  .logo-img {
    height: 37px;
    width: 164px;
    position: absolute;
    top: 50%;
    left: calc(50% - 82px);
    z-index: 99999999;
    animation: appear linear .2s;
    -webkit-animation: appear linear .2s;
    -moz-animation: appear linear .2s;
  }
  @keyframes appear {
    0% {
      transform: translate(-41px);
    }
    100% {
      transform: translate(0px);
    }
  }
  @-webkit-keyframes appear {
    0% {
      -webkit-transform: translate(-41px);
    }
    100% {
      -webkit-transform: translate(0px);
    }
  }
  @-moz-keyframes appear {
    0% {
      -moz-transform: translate(-41px);
    }
    100% {
      -moz-transform: translate(0px);
    }
  }

  .icon-ap {
    .image {
      position: absolute;
      display: flex;
      top: 50%;
      left: calc(50% - 5px);
      width: 20px;
      height: 20px;
      z-index: 99999999;
      -webkit-animation: spin 2s infinite;
      -moz-animation: spin 2s infinite;
      animation: spin 2s infinite;
    }
    @keyframes spin {
      0% {
        transition: width;
        width: 20px;
        height: 20px;
        left: calc(50% - 5px);
        transform: rotate(90deg);
      }
      25% {
        transition: width;
        width: 40px;
        height: 40px;
        left: calc(50% - 15px);
        transform: rotate(180deg);
      }
      50% {
        transition: width;
        width: 20px;
        height: 20px;
        left: calc(50% - 5px);
        transform: rotate(270deg);
      }
      75% {
        transition: width;
        width: 40px;
        height: 40px;
        left: calc(50% - 15px);
        transform: rotate(360deg);
      }
      100% {
        transition: width;
        width: 20px;
        height: 20px;
        left: calc(50% - 5px);
        transform: rotate(450deg);
      }
    }
    @-webkit-keyframes spin {
      0% {
        -webkit-transition: width;
        width: 20px;
        height: 20px;
        left: calc(50% - 5px);
        -webkit-transform: rotate(90deg);
      }
      25% {
        -webkit-transition: width;
        width: 40px;
        height: 40px;
        left: calc(50% - 15px);
        -webkit-transform: rotate(180deg);
      }
      50% {
        -webkit-transition: width;
        width: 20px;
        height: 20px;
        left: calc(50% - 5px);
        -webkit-transform: rotate(270deg);
      }
      75% {
        -webkit-transition: width;
        width: 40px;
        height: 40px;
        left: calc(50% - 15px);
        -webkit-transform: rotate(360deg);
      }
      100% {
        -webkit-transition: width;
        width: 20px;
        height: 20px;
        left: calc(50% - 5px);
        -webkit-transform: rotate(450deg);
      }
    }
    @-moz-keyframes spin {
      0% {
        -moz-transition: width;
        width: 20px;
        height: 20px;
        left: calc(50% - 5px);
        -moz-transform: rotate(90deg);
      }
      25% {
        -moz-transition: width;
        width: 40px;
        height: 40px;
        left: calc(50% - 15px);
        -moz-transform: rotate(180deg);
      }
      50% {
        -moz-transition: width;
        width: 20px;
        height: 20px;
        left: calc(50% - 5px);
        -moz-transform: rotate(270deg);
      }
      75% {
        -moz-transition: width;
        width: 40px;
        height: 40px;
        left: calc(50% - 15px);
        -moz-transform: rotate(360deg);
      }
      100% {
        -moz-transition: width;
        width: 20px;
        height: 20px;
        left: calc(50% - 5px);
        -moz-transform: rotate(450deg);
      }
    }
  }
}