.login {
  height: 100vh;
  .row-logo-login {
    align-items: center;
    align-content: center;
    height: 100%;
    border-right: 1px solid #c4c4c4;
  }
  .login_container {
    background: #ffffff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 42px;
    min-height: 414px;
  }
  .text_login {
    p {
      color: #565656;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 40px;
      span {
        font-weight: 700;
      }
    }
  }
  .container_input_login {
    margin-left: -3px;
    margin-right: -3px;
    .col_input_login {
      position: relative;
    }
    label {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 32px;
      color: #00379b;
    }
    .input_login {
      border: 1px solid #c4c4c4;
      border-radius: 10px;
      margin-bottom: 16px;
      margin-left: 0px;
      margin-right: 0px;
      width: 100%;
      padding: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $site-primary-color;
    }
    .input_login_error {
      border: 1px solid #dd1f36;
    }
    .label_login_error {
      color: #dd1f36;
    }
    .span_login_error {
      position: absolute;
      top: 82px;
      left: 16px;
      color: #bf0e0e;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
    }
  }
  .button_login {
    background: #00379b;
    border-radius: 10px;
    width: 100%;
    height: 48px;
    border: transparent;
    color: white;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    margin-top: 16px;
  }
  .input_login:focus-visible {
    outline: transparent;
  }
}
