@import '../../styles/settings/global';

.switch {
  width: 200px;

  .no-lateral-padding {
    padding-left: 0px;
    padding-right: 0px;
  }

  .switch-label {
    display: block;
    float: right;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 40px;
    text-align: right;
    color: #000000;
  }

  .local-switch {
    .react-switch-bg {
      background-color: #ffffff !important;
      border: 2px solid #c4c4c4;
    }
  }

  .react-switch-handle {
    box-shadow: none !important;
  }
}
