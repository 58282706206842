.container-collapse {
  position: initial;

  .section-item-collapse {
    position: relative !important;
    padding: 0px 11px 0px 18px !important;
    overflow: initial !important;
    margin-bottom: 0px;
    z-index: 1;
    box-shadow: none !important;

    .MuiCardContent-root:last-child {
      padding: 10px 0px !important;
    }

    .MuiCollapse-wrapper {
      display: initial;
    }

    .grid-item-text {
      align-self: center;
    }

    .section-item {
      margin: 0px;
      padding: 10px 0px;

      &__blue {
        border-top: 0.5px solid #144b96;
        border-radius: 0px !important;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
      }
      &__red {
        border-top: 0.5px solid #e9352c;
        border-radius: 0px !important;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
      }
      &__green {
        border-top: 0.5px solid #39ac00;
        border-radius: 0px !important;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
      }
      &__mint {
        border-top: 0.5px solid #246c74;
        border-radius: 0px !important;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
      }
      &__wine {
        border-top: 0.5px solid #743766;
        border-radius: 0px !important;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
      }
      &__rainbow {
        border-top: 0.5px solid
          linear-gradient(88.04deg, #e21e34 1.54%, #246c73 98.35%);
        border-radius: 0px !important;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
      }
    }

    .container-section {
      padding: 11px 0px 11px 0px !important;
    }
  }

  .card-action {
    width: 100%;
    padding: 0px !important;
    top: -15px;
    text-align: center;
    height: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: relative;

    &__default {
      background-color: #ffffff;
    }
    &__blue {
      background-color: #144b96;
    }
    &__red {
      background-color: #e9352c;
    }
    &__green {
      background-color: #39ac00;
    }
    &__mint {
      background-color: #246c74;
    }
    &__wine {
      background-color: #743766;
    }
    &__rainbow {
      background-color: linear-gradient(
        88.04deg,
        #e21e34 1.54%,
        #246c73 98.35%
      );
    }
    .action-btn {
      position: relative;
      margin: auto;
      padding: 0px;
      font-size: 36px;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      top: -10px;

      &__default {
        background-color: #ffffff !important;
      }

      &__blue {
        background-color: #144b96 !important;
      }
      &__red {
        background-color: #e9352c !important;
      }
      &__green {
        background-color: #39ac00 !important;
      }
      &__mint {
        background-color: #246c74 !important;
      }
      &__wine {
        background-color: #743766;
      }
      &__rainbow {
        background-color: linear-gradient(
          88.04deg,
          #e21e34 1.54%,
          #246c73 98.35%
        );
      }
      svg {
        position: relative;
        top: 7px;
        path {
          fill: white;
        }
      }
    }
  }
}
