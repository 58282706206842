html,
body {
  color: $site-text-color;
  background-color: $site-background;
  font-family: 'Roboto';

  .no-lateral-padding {
    padding-left: 0px;
    padding-right: 0px;
  }
}
