.faq_add_new_box_Disabled {
  background: #c4c4c4 !important;
  cursor: default !important;
}

.label_custom_Switch {
  color: #000000;
  font-weight: bold;
  margin-right: 10px;
}

.preview-data {
  margin-top: 15px;
}

.faq_add_new_box {
  background: $site-primary-color;
  border-radius: 10px;
  margin-left: 0px;
  margin-right: 0px;
  align-items: center;
  min-height: 48px;
  cursor: pointer;

  .faq_add_new_text {
    p {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      align-items: center;
      color: #ffffff;
      margin: 0px;
    }
  }

  .faq_add_new_icon {
    i {
      font-size: 23px;
      color: #ffffff;
      margin-top: 5px;
    }
  }
}

.faq_add_new_faq_row {
  border-radius: 10px;
  margin-left: 0px;
  margin-right: 0px;
  align-items: center;
  min-height: 48px;
}

.faq_add_new_faq_col {
  background: #f0f0f0;
  border-radius: 10px !important;

  .faq_add_new_faq {
    background: #f0f0f0;
    border-bottom: transparent !important;
    align-items: center;
    padding: 7px 1px 7px 1px;

    .faq_add_new_faq_text {
      cursor: auto !important;

      textarea,
      div,
      input {
        background-color: transparent;
        border: transparent;
        width: 100%;
        padding: 5px;
        font-style: normal;
        font-weight: bold;
        font-size: 1.1rem;
        line-height: 21px;
        margin: 0px;
        color: $site-primary-color;
      }
      input::placeholder {
        color: #c4c4c4;
      }
      input:focus-visible {
        outline: none;
      }
      textarea::placeholder {
        color: #c4c4c4;
      }
      textarea:focus-visible {
        background-color: transparent;
        border: transparent;
        outline: transparent;
        outline: none;
      }
      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .faq_delete_faq {
      width: 24px;
      height: 24px;
      color: $site-primary-color;
      margin-top: 3px;
      cursor: pointer;

      &__empty {
        color: #c4c4c4;
      }
    }
  }

  .faq_add_new_faq_textarea {
    width: 100%;
    background-color: transparent;
    border-color: transparent;
    color: #000000;
    margin-top: 15px;
  }

  .faq_add_new_faq_textarea:focus-visible {
    outline: none;
  }

  &__empty {
    .label_custom_Switch {
      color: #c4c4c4;
    }

    .toggle-switch {
      .toggle-switch-label {
        .toggle-switch-inner:before,
        .toggle-switch-inner:after {
          background-color: #c4c4c4;
        }
      }
    }

    .faq_add_new_faq_textarea::placeholder {
      color: #c4c4c4;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
    .faq_add_new_faq_textarea:focus-visible {
      outline: none;
    }
  }

  .collapse,
  .collapsing,
  .show {
    margin: 30px;
    margin-top: 10px;
    color: #000000;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
}

.active_faq {
  color: $site-primary-color;
}

.icon-triangle-up,
.icon-triangle-down {
  color: $site-primary-color;
  margin-top: 5px;
  cursor: pointer;
}

.faq_confirmButton {
  width: 100%;
  background-color: $site-primary-color;
  border-radius: 10px;
  padding: 10px;
  border: transparent;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.faq_closeButton {
  width: 100%;
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  padding: 9px;
  border: 1px solid $site-primary-color;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: $site-primary-color;
}
