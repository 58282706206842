.home-preview_Background {
  background-color: #fff;
  border-radius: 25px;
  padding: 10px;
  margin-right: 0px;
  width: 430px;
  margin-left: 40px !important;
  min-height: 100%;
  height: fit-content;
  height: -webkit-fill-available;
}
.home-preview_Background_schedule {
  background-color: #fff;
  border-radius: 25px;
  padding: 10px;
  margin-right: 0px;
  width: 430px;
  margin-left: 32px !important;
}

.home-preview {
  background: linear-gradient(
    0deg,
    #f0f0f0 82.08%,
    rgba(240, 240, 240, 0) 92.57%
  );
  padding: 0px;
  border-radius: 20px;

  .icon-logout-menu {
    float: left;
    margin-left: 3px;

    .demoIconPlus {
      background: linear-gradient(88.04deg, #e21e34 1.54%, #246c73 98.35%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 44px;
    }
  }

  .home-header {
    padding: 20px 25px;
    padding-bottom: 0px;

    .icon-login {
      float: right;
      margin-left: 9px;

      .demoIconPlus {
        font-size: 44px;
      }
    }

    .icon-logout {
      float: right;
      margin-left: 9px;

      .demoIconPlus {
        background: linear-gradient(88.04deg, #e21e34 1.54%, #246c73 98.35%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 44px;
      }
    }

    .welcome {
      width: 100%;
      display: inline-block;
      font-style: normal;
      font-weight: normal;
      font-size: 21px;
      line-height: 25px;
      text-align: left;
      color: #000000;
      margin-top: 30px;
    }

    .title-header {
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 30px;
      text-align: center;
      color: #000000;
      margin-top: 30px;
      margin-bottom: 15px;
    }

    .logo-mobile {
      display: inline-block;

      .mall-selected {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        align-items: center;
        color: #6e6e6e;
        margin-left: 10px;

        .mall-name {
          color: #000000;
          font-weight: bold;
        }
      }

      .logo-img {
        width: 157px;
        margin-top: 4px;
        margin-left: 10px;
      }
    }

    .information {
      float: right;

      .information-img {
        width: 44px;
      }
      .demoIconPlus {
        font-size: 44px;
      }
    }

    .menu {
      float: right;

      .menu-icon {
        width: 30px;
        height: 32px;
        margin: 7px 3px;
      }
    }

    .container-input {
      .contenedor-buscador {
        padding: 7px 20px;
        width: 100%;
        border-radius: 20px;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
        border: none !important;
        background: white;

        .column {
          padding: 0px;
          padding-left: 10px;
          .icon-search {
            width: 20px;
            height: 20px;
            margin: auto;
          }
        }
      }
    }
  }
}
