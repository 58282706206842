.alert-section {
  padding: 0px 30px;
  padding-top: 30px;

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 25px;
    color: #000000;
    margin-bottom: 10px;

    span {
      white-space: nowrap;
    }
  }
}
