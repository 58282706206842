@import '../../styles/settings/global';

.header {
  //height: $header-heigth;
  &__logo {
    height: 40px;
    margin: 0px 20px;
  }

  nav.navbar {
    display: none;
    background-color: white;
    box-shadow: none;
  }

  .nav-item {
    padding: 0px 5px;
    color: $site-primary-color;

    svg {
      height: 1.3em;
      width: 1.3em;
    }

    &-user {
      padding: 0px 20px;
    }

    &:not(:last-child) {
      border-right: 1px solid #c4c4c4;
    }
  }
}
