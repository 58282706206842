.notificationUnitaria {
  text-align: left;
  position: absolute;
  top: 389px;
  background-color: white;
  min-height: 79px;
  width: 280px;
  p {
    font-weight: 400;
    line-height: 16px !important;
    margin-top: 10px !important;
  }

  .divImg {
    .img {
      width: 60px;
      height: 21px;
    }
  }

  .title {
    font-size: 14px !important;
    font-weight: 700;
  }

  .description {
    font-size: 12px !important;
    margin-bottom: 10px !important;
  }
}

.noNotification {
  display: inline !important;
  text-align: center;

  img {
    margin-top: 20px;
    width: 31.9px !important;
    height: 31.9px !important;
  }

  p {
    margin-top: 10px;
    font-size: 14px;
  }

  .demoIconPlus {
    margin-top: 10px;
    .degrade {
      background: linear-gradient(
        83.15deg,
        #c62440 -6.1%,
        #803460 30.61%,
        #2f4784 66.73%,
        #246c74 105.76%
      ) !important;
      -webkit-background-clip: text !important;
      -webkit-text-fill-color: transparent !important;
    }
  }
}

.imagen-bg-notification {
  position: relative;
  left: 234px;
}

.newMall_background-notification {
  min-height: 80vh;
}

.rowDate {
  position: relative;
}

.aspan {
  position: absolute;
  left: 220px;
  bottom: 14px;
  font-weight: 700;
}

.inputDate {
  padding: 8px 14px;
  border: 0.8px solid #00379b;
  border-radius: 10px;
  gap: 10px;
  min-width: 200px;
}

.inputDate:focus-visible {
  outline: 0.8px solid #00379b !important;
  border-radius: 10px;
}

.title-preview {
  margin-top: 48px;
}
