.carousel-section {
  margin: 0px 30px;
  margin-top: 30px;
  height: 130px;
  box-sizing: border-box;

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 25px;
    color: #000000;
    margin-bottom: 10px;

    span {
      white-space: nowrap;
    }
  }

  .carousel-root {
    .carousel,
    .carousel-slider {
      border-radius: 20px !important;

      .control-dots {
        margin: 0px;
        .dot {
          width: 10px;
          height: 10px;
          background: rgba(20, 75, 150, 0.7);
          border: 1px solid #ffffff;
          opacity: 1;
        }
        .selected {
          background: white;
        }
      }

      .slider-wrapper {
        ul {
          li {
            img {
              height: 130px;
              border-radius: 20px !important;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }
    }
  }
}
