.modal_box,
.modal_box_common {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal_alert_index {
  z-index: 9999 !important;
}

.modal-header {
  z-index: 99;
  border-bottom: none;
  padding: 0px;
  padding-top: 30px;
}

.modal_body {
  z-index: 99;
  padding: 24px 0px;
  h4 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #000000;
  }
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    margin-bottom: 0px;
    color: #000000;
  }
}
.modal_content {
  z-index: 99;
  border-radius: 23px !important;
  padding: 24px;
  height: 699px;
  width: 414px;
}
.modal-footer {
  padding: 0px;
  border-top: none;
  display: flex;

  .button-container {
    margin: 0px;
  }

  .modal_confirmButton {
    width: 100%;
    background-color: #0250b6;
    border-radius: 10px;
    padding: 7px;
    border: transparent;

    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    align-items: center;
    text-align: center;
    margin-bottom: 16px;
    color: #ffffff;
  }
  .modal_closeButton {
    width: 100%;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    padding: 7px;
    border: 1px solid $site-primary-color;

    margin-bottom: 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 25px;
    text-align: center;
    color: $site-primary-color;
  }
}

.modal_Button {
  width: 100%;
  background-color: #0250b6;
  border-radius: 10px;
  padding: 7px;
  border: transparent;
  margin-top: 37px;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  align-items: center;
  text-align: center;
  margin-bottom: 16px;
  color: #ffffff;
}

.modal_box_common {
  .modal_content {
    height: auto;
  }

  .modal-footer {
    display: block;

    .modal_closeButton {
      margin-bottom: 0px;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      padding: 12px;
    }

    .modal_confirmButton {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      padding: 13px;
    }
  }
}
