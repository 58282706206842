.templateHome {
  .vertical-line {
    border-left: 2px solid #dadada;
    height: 95%;
    max-width: 72px;
    margin: 10px 25px;

    &__A {
      border-color: #dd1f36;
    }

    &__B {
      border-color: #743766;
    }

    &__C {
      border-color: #334783;
    }

    &__D {
      background-color: #246C74;
    }
    &__E {
      background-color: #743766;
    }
    &__F {
      background-color: #334783;
    }
    &__G {
      background-color: #246c74;
    }

    .letter {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: #dadada;
      color: white;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      align-items: center;
      text-align: center;
      padding: 3px 7px;
      position: relative;
      display: flex;
      top: calc(50% - 13px);
      left: -13px;

      &__A {
        background-color: #dd1f36;
      }

      &__B {
        background-color: #743766;
      }

      &__C {
        background-color: #334783;
      }

      &__D {
        background-color: #246C74;
      }
      &__E {
        background-color: #743766;
      }
      &__F {
        background-color: #334783;
      }
      &__G {
        background-color: #246c74;
      }
    }
  }

  .col-letter-section {
    margin: 0px 0px;
    margin-top: 30px;

    .letter-section {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      padding: 5px 0px;

      .letter {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: #dadada;
        color: white;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        align-items: center;
        text-align: center;
        padding: 3px 7px;
        display: inline-block;
        margin-right: 10px;

        &__A {
          background-color: #dd1f36;
        }

        &__B {
          background-color: #743766;
        }

        &__C {
          background-color: #334783;
        }

        &__D {
          background-color: #246C74;
        }
        &__E {
          background-color: #743766;
        }
        &__F {
          background-color: #334783;
        }
        &__G {
          background-color: #246c74;
        }
      
      }
    }
  }

  .templateHome_background,
  .templateHome_background_start,
  .templateHome_background_end {
    .home-background {
      background: linear-gradient(
        0deg,
        #f0f0f0 22.08%,
        rgba(240, 240, 240, 0) 92.57%
      );
      padding: 0px;

      &__2 {
        background: rgb(239, 239, 239);
      }

      &__special {
        padding: 15px 0px;
      }

      &__carousel {
        padding-top: 15px;
      }

      .special-item-new {
        background: none;
        border: 2px dashed #c4c4c4;
        box-sizing: border-box;
        border-radius: 15px;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        align-items: center;
        text-align: center;
        color: #c4c4c4;
        padding: 10px 15px;
        margin: 0px 30px;
      }

      .home-header {
        padding: 20px 25px;
        padding-bottom: 0px;

        .icon-login {
          float: right;
          margin-left: 9px;

          .demoIconPlus {
            font-size: 44px;
          }
        }

        .icon-logout {
          float: right;
          margin-left: 9px;

          .demoIconPlus {
            background: linear-gradient(
              88.04deg,
              #e21e34 1.54%,
              #246c73 98.35%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 44px;
          }
        }

        .welcome {
          width: 100%;
          display: inline-block;
          font-style: normal;
          font-weight: normal;
          font-size: 21px;
          line-height: 25px;
          text-align: left;
          color: #000000;
          margin-top: 30px;
        }

        .title-header {
          font-style: normal;
          font-weight: bold;
          font-size: 26px;
          line-height: 30px;
          text-align: center;
          color: #000000;
          margin-top: 70px;
          margin-bottom: 15px;
        }

        .logo-mobile {
          float: left;

          .mall-selected {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            align-items: center;
            color: #6e6e6e;
            margin-left: 10px;

            .mall-name {
              color: #000000;
              font-weight: bold;
            }
          }

          .logo-img {
            width: 157px;
            margin-top: 4px;
            margin-left: 10px;
          }
        }

        .information {
          float: right;

          .information-img {
            width: 44px;
          }
          .demoIconPlus {
            font-size: 44px;
          }
        }

        .menu {
          float: right;

          .menu-icon {
            width: 30px;
            height: 32px;
            margin: 7px 3px;
          }
        }

        .container-input {
          padding-bottom: 15px;

          .contenedor-buscador {
            padding: 7px 20px;
            width: 100%;
            border-radius: 20px;
            box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
            border: none !important;
            background: white;

            .column {
              padding: 0px;
              padding-left: 10px;
              .icon-search {
                width: 20px;
                height: 20px;
                margin: auto;
              }
            }
          }
        }
      }

      .section {
        padding-bottom: 15px;
        padding-top: 10px;

        &__white {
          margin-top: 0px;
        }

        .title_empty {
          color: #a6a6a6 !important;
          cursor: pointer;
        }
        .input-title:focus-visible {
          border: transparent;
          outline: 1px solid #00379b;
        }

        .title {
          color: #000000;
          cursor: pointer;
          .input-title {
            border-radius: 10px;
            background: #ffffff;
            border: 1px solid #c4c4c4;
            box-sizing: border-box;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 21px;
            align-items: center;
            color: #000000;
            width: 100%;
            padding: 10px;
          }

          .check-title-btn,
          .cancel-title-btn {
            float: right;
            background-color: #ffffff;
            border: 1px solid #a6a6a6;
            padding: 0px 5px;
            margin-bottom: 1px;
            margin-right: 2px;
            border-radius: 3px;
            cursor: pointer;

            .check-title-edit,
            .cancel-title-edit {
              width: 15px;
              color: #a6a6a6;
            }
          }

          .check-title-btn:hover,
          .cancel-title-btn:hover {
            background-color: #c4c4c4;

            .check-title-edit,
            .cancel-title-edit {
              color: #ffffff;
            }
          }
        }

        .section-item,
        .sub-bloque-item {
          height: 80px;

          .title-item {
            line-height: 20px;
          }

          &__default {
            margin: 0px !important;
            margin-top: 20px !important;
          }

          &__new {
            height: 80px;
            background: none;
            border: 2px dashed #c4c4c4;
            box-sizing: border-box;
            border-radius: 20px;
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            align-items: center;
            text-align: center;
            color: #c4c4c4;
            padding: 28px 11px;
          }

          &__with-sub-bloque {
            border-bottom-left-radius: 0% !important;
            border-bottom-right-radius: 0% !important;
          }

          .edit {
            position: relative;
            display: flex;
            bottom: 3px;
            left: 0px;
            border-radius: 50%;
            background: #00379b;
            width: 24px;
            height: 24px;
            cursor: pointer;
          }

          .add-item {
            position: relative;
            display: flex;
            bottom: 27px;
            left: 51px;
            border-radius: 50%;
            background: #00379b;
            width: 24px;
            height: 24px;
            cursor: pointer;
          }

          .delete {
            position: relative;
            display: flex;
            top: -110px;
            right: -291px;
            border-radius: 50%;
            background: #c30c25;
            width: 24px;
            height: 24px;
            cursor: pointer;

            &__secundary {
              top: -106px;
            }
          }
          .delete_half {
            position: relative;
            display: flex;
            top: -99px;
            right: -133px;
            border-radius: 50%;
            background: #c30c25;
            width: 24px;
            height: 24px;
            cursor: pointer;

            &__secundary {
              top: -106px;
            }
          }
        }

        .sub-bloque-item {
          margin-top: 5px;

          border-radius: 0% !important;

          &__end {
            border-bottom-left-radius: 20px !important;
            border-bottom-right-radius: 20px !important;
          }

          .delete {
            top: -106px;
          }
        }
      }

      .carousel-section {
        margin-top: 0px;

        .edit-carousel {
          position: relative;
          display: flex;
          bottom: 13px;
          left: 39px;
          border-radius: 50%;
          background: #00379b;
          width: 24px;
          height: 24px;
          z-index: 1;
          cursor: pointer;
        }

        .delete-carousel {
          position: relative;
          display: flex;
          top: -160px;
          right: -314px;
          border-radius: 50%;
          background: #c30c25;
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }

      .alert-section {
        padding: 25px 30px;

        .edit-alert {
          position: relative;
          display: flex;
          bottom: 13px;
          left: 39px;
          border-radius: 50%;
          background: #00379b;
          width: 24px;
          height: 24px;
          z-index: 1;
          float: left;
          cursor: pointer;
        }

        .delete-alert {
          position: relative;
          display: flex;
          top: -89px;
          right: -1px;
          border-radius: 50%;
          background: #c30c25;
          width: 24px;
          height: 24px;
          float: right;
          cursor: pointer;
        }
      }

      .icon-edit {
        width: 20px;
        height: 20px;
        color: white;
        margin: 2px;
      }

      .icon-add {
        width: 20px;
        height: 20px;
        color: white;
        margin: 2px;
        font-weight: bold;
      }

      .icon-delete {
        width: 20px;
        height: 20px;
        color: white;
        margin: 2px;
        font-weight: bold;
      }
    }
  }
}

.test {
  cursor: pointer;
  height: 100%;
  width: 100%;
  background-color: transparent;
  z-index: -1;
  position: relative;
  left: 10px;
}
