.notification-add {
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000;
  .icon-add-notification {
    font-size: 30px;
    cursor: pointer;
    path {
      stroke: #00379b;
    }
  }
}

.notification-list-box {
  height: 72px;
  background: #ffffff;
  border-radius: 10px;
  position: relative;
  border: 1px solid #154bae;
  .icon_listNotification {
    color: #be231e;
    font-size: 80px;
    margin-left: -20px;
  }
  .icon-listNotification {
    padding: 0px 0px 0px 12px;
    img {
      min-width: 11px;
      max-width: 45px;
    }
  }

  .vigency-listNotification {
    position: absolute;
    bottom: 0px;
    right: 11px;
    font-family: 'Roboto';
    font-size: 10px;
    font-weight: 400;
  }

  .notification-name {
    p {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      margin: 0px;
      color: #000;
      padding: 0px 10px 0px 0px;
    }
  }
}

.notification-list-box-disabled {
  height: 72px;
  background: #f0f0f0;
  border-radius: 10px;
  position: relative;
  border: 1px solid #c4c4c4;
  .icon_listNotification {
    color: #be231e;
    font-size: 80px;
    margin-left: -20px;
  }
  .icon-listNotification {
    padding: 0px 0px 0px 12px;
    img {
      min-width: 45px;
      max-width: 45px;
    }
  }
  .vigency-listNotification {
    position: absolute;
    bottom: 0px;
    right: 11px;
    font-family: 'Roboto';
    font-size: 10px;
    font-weight: 400;
  }

  .notification-name {
    p {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      margin: 0px;
      color: #c4c4c4;
      padding: 0px 10px 0px 0px;
    }
  }
}

.active {
  background-color: transparent;
  padding: 0px;
  border-radius: 0px;
}

.notification_list_button {
  position: relative;
}
.notification_list_arrow {
  font-size: 2rem;
  position: absolute;

  path {
    stroke: white;
  }
}

._R {
  right: 35px;
  top: 3px;
}
._L {
  left: 35px;
  top: 3px;
}

.minHeight_ListNotification {
  min-height: 433px;
  .listNoNotification_card {
    position: relative;
    top: 115px;
  }
}
.listNoNotification_card {
  min-height: 219px;

  div {
    background: #ffffff;
    border-radius: 20px;
  }
  .text_NoNotification {
    text-align: center;
    margin-top: 55px;
    p {
      color: #000;
      font-weight: 700;
      font-size: 24px;
    }
  }
}

.loaderPosition {
  position: relative;
  top: 38vh;
  right: 10vw;
}

.noListNotification {
  height: 45px;
}
