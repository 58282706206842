/**
* Global Site Settings
*
**/

$font-path: '../';
$icons-path: '../';

$site-font-family: 'Arial';
$site-font-size: 16px;
$site-text-color: #666;
$site-background: #fff;
$site-primary-color: #00379b;
$components-background: #f0f0f0;

$sidenav-width: 89px;
$header-heigth: 66px;
