.btn-close {
  border-radius: 50%;
  background: #c4c4c4;
  padding: 0px;
  float: right;
  cursor: pointer;

  .icon-close {
    align-content: center;
    vertical-align: middle;
    path {
      fill: white;
    }
  }
}

.updateImage_delete {
  position: relative;
  display: flex;
  top: 64px;
  right: 40px;
  border-radius: 50%;
  background: #c30c25;
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 2;
  float: right;

  .icon-delete {
    width: 20px;
    height: 20px;
    color: white;
    margin: 2px;
    font-weight: bold;
  }
}

.updateImage_box {
  padding: 16px;
  margin: 16px 2px 30px 2px;
  background: #f5f5f5;
  border-radius: 13px;

  &__no-margin-top {
    margin-top: 0px;
  }

  .updateImage_insidebox {
    padding: 20px 20px 15px 20px;
    background-color: white;
    border: 1px dashed #c4c4c4;
    border-radius: 10px;
    text-align: center;

    &__noborder {
      border: none;
    }

    &__dragging {
      border: 2px dashed $site-primary-color;
    }
  }
  .updateImage_insidebox_notification {
    padding: 0px;
    border: 2px dashed #c4c4c4;
  }
  .updateImage_image {
    max-width: 350px;
    margin: 30px 20px;
    margin-bottom: 40px;
  }

  .icon-upload {
    width: 44px;
    height: 40px;
    margin: 10px auto;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom: 2px solid $site-primary-color;

    .upload {
      height: 25px;
      width: 25px;
      path {
        stroke: $site-primary-color;
      }
    }
  }

  .text-upload {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    color: #6f6f6f;

    &__dragging {
      color: $site-primary-color;
    }
  }

  .text-upload-bottom {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    align-items: center;
    text-align: center;
    color: #6f6f6f;
    margin: 5px 0px;

    .text {
      display: inline-block;
      margin: 0px 10px;
    }

    .horizontal-line {
      display: inline-block;
      width: 66px;
      border-bottom: 1px solid #6f6f6f;
      line-height: 10px;
      margin-bottom: 4px;
    }
  }

  .updateImage_bluebutton {
    width: 240px;
    cursor: pointer;
    background-color: $site-primary-color;
    color: white;
    border: transparent;
    border-radius: 10px;
    padding: 8px 0px;
  }
  .updateImage_greybutton {
    width: 240px;
    cursor: pointer;
    background-color: rgb(184, 184, 184);
    color: white;
    border: transparent;
    border-radius: 10px;
    padding: 8px 0px;
  }
  .updateImage_input {
    display: none;
  }
}

.updateImage_box_notification {
  margin: 0px;
  padding: 0px !important;
  background: none !important;
  min-height: 167px !important;
}

form {
  margin-top: 20px;
}

.updateImage_subTitles {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.message {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  align-items: center;
  text-align: center;
  color: #00379b;

  &__warning {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #be231e;
  }
}

.container-btns {
  margin-top: 30px;
  padding: 0px;
  padding-left: 16px;
  position: absolute;
  bottom: -16px;
  width: 104%;

  .updateImage_confirmButton {
    width: 100%;
    background-color: $site-primary-color;
    border-radius: 10px;
    padding: 10px;
    border: transparent;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    margin-bottom: 16px;
    color: #ffffff;
  }
  .updateImage_confirmButtonDisable {
    width: 100%;
    background-color: #c4c4c4;
    border-radius: 10px;
    padding: 10px;
    border: transparent;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    margin-bottom: 16px;
    color: #ffffff;
  }
  .updateImage_closeButton {
    width: 100%;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    padding: 9px;
    border: 1px solid $site-primary-color;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: $site-primary-color;
  }
}
