@import '../../styles/settings/global';

.container-menu {
  background-color: $site-primary-color;
  padding: 32px 32px 50px 50px;
  margin-top: 38px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  color: white;
  max-width: 376px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;

  .container-select-mall {
    .mall-select {
      margin: 0px;

      input {
        border: none;
        background-color: white;
        border-radius: 10px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        align-items: center;
        padding-left: 16px;
        width: 95%;
      }

      span.caret {
        position: absolute;
        top: 0.8rem;
        right: 15px;
        font-size: 0.7rem;
        color: #00379b;
        z-index: 5;
      }
    }
  }

  .horizontal-line {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid white;
    line-height: 10px;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .horizontal-line-bottom {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid white;
    line-height: 10px;
    margin-top: 27px;
    margin-bottom: 9px;
  }
  .menu_logout {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    border-radius: 10px;
    padding: 12px 16px 12px 0px;
    cursor: pointer;
    .menu_logout_icon_container {
      svg {
        width: 22px;
        height: 22px;
        margin-right: 10px;
      }
    }
  }

  .menu_logout:hover {
    border-radius: 10px;
    padding: 12px 16px 12px 0px;
    background-color: #154bae;
  }

  .link-box {
    align-items: center;
    border-radius: 10px;
    padding: 12px 16px 12px 0px;
  }
  .link-box:hover {
    border-radius: 10px;
    padding: 12px 16px 12px 0px;
    background-color: #154bae;
  }

  .icon-add-mall {
    width: 22px;
    height: 22px;
    margin-right: 15px;
    path {
      stroke: white;
    }
  }

  .icon-schedules {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    path {
      stroke: white;
    }
  }

  a {
    color: white !important;
  }
}
