.schedule_block {
  background: #ffffff;
  padding: 16px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
  .schedule_times {
    margin-bottom: 16px;
    background: #f0f0f0;
    border-radius: 10px;
    padding: 6px;
    color: #000;
    font-weight: 700;
    font-size: 16px;
  }
  .active_time {
    background: #00379b;
    color: #fff;
    cursor: pointer;
  }
  .schedule_edit_container {
    position: relative;
    span {
      display: none;
    }

    .schedule_edit {
      width: 26px;
      border-radius: 20px;
      position: absolute;
      border: 1px solid #fff;
      background-color: #00379b;
      top: -16px;
      right: 8px;
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
  .schedule_edit_container:hover {
    span {
      display: block;
    }
  }
}
.container-title-schedule {
  margin-top: -6px !important;
  .title {
    margin-left: 20px;
    color: #000 !important;
  }
}

.color_schedule_edit_icon {
  color: white;
}

.divisor_schedule {
  width: 100%;
  margin-bottom: 16px;
  border-bottom: 1px solid #c4c4c4;
}

// BLOCK ===========

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.schedule_edit_block {
  min-height: 160px;
  position: relative;
}
.delete_schedule {
  position: absolute;
  right: 35px;
  top: -16px;
  svg {
    color: #c30c25;
    font-size: 25px;
  }
}

.schedule_edit_block_time {
  position: relative;
  background-color: #00379b;
  border: 1px solid #cecece;
  border-radius: 10px;
  margin: 16px 0px;
  color: #fff;
  height: 32px;
  padding: 0px 4px 4px 0px;
  input {
    background-color: transparent;
    outline: transparent;
    border: transparent;
    color: #fff;
  }
  span {
    position: absolute;
    right: 10px;
    top: 2px;
  }
}
.schedule_edit_block_time:focus-within {
  outline: transparent;
  border: transparent;
}
.schedule_edit_block_comment {
  label {
    color: #00379b;
    font-weight: 700;
    font-size: 16px;
  }
  input {
    background: #f0f0f0;
    border-radius: 10px;
    margin-bottom: 16px;
    color: #00379b;
    height: 32px;
    padding: 10px;
    outline: transparent;
    border: transparent;
  }
}
.schedule_edit_block_comment:focus-within {
  outline: transparent;
  border: transparent;
}
.bloqs_edit_block_title {
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  color: #6f6f6f;
}

.text_alert_error {
  font-size: 0.9rem;
}

.margin_schedule {
  margin-right: 30px;
}
