.section {
  padding: 0px 20px;
  padding-top: 30px;

  &__white {
    background: white !important;
    padding: 30px 10px 20px 10px !important;
    margin-top: 31px;
  }

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 25px;
    color: #000000;

    span {
      white-space: nowrap;
    }
  }

  .grid {
    padding-left: 0px;
    padding-right: 0px;
    align-items: center;
    display: flex;
    width: 100%;
    margin: auto;
    margin-top: 0px;
  }

  .col-padding1 {
    padding-left: 0px;
    padding-right: 4px;
    height: 100%;
  }

  .col-padding2 {
    padding-left: 4px;
    padding-right: 0px;
    height: 100%;
  }
  .col-grid-stretch {
    display: grid;
    align-self: stretch;
  }
}
