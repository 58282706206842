.label_title_text {
  color: #000;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}

.minSizeNotification {
  min-height: 700px;
}

.edit_inputs {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 16px;
  height: 48px;
  padding: 0px 15px;
}
.edit_inputs:focus-within {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  outline: transparent;
  box-sizing: border-box;
  border-radius: 10px;
}

.textarea_notification {
  height: 104px;
  margin-bottom: 10px;
}

.disabled {
  .caret {
    color: #c4c4c4 !important;
  }
}

.box_icon_notification {
  background-color: #ffffff;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  margin-left: 10px;
}
.box_icon_notification:hover {
  outline: 1px solid #00379b;
  cursor: pointer;
}
.checked_notification {
  outline: 1px solid #00379b;
}

.input_notification_check {
  input[type='checkbox'],
  input[type='checkbox']:checked,
  input[type='checkbox']:not(:checked) {
    display: inline-block;
    position: relative;
    pointer-events: auto;
    opacity: 1;
    -ms-transform: scale(1.3); /* IE */
    -moz-transform: scale(1.3); /* FF */
    -webkit-transform: scale(1.3); /* Safari and Chrome */
    -o-transform: scale(1.3); /* Opera */
    transform: scale(1.3);
  }
  color: #000;
  font-weight: 400;
}

.input_notification_radio {
  input[type='radio'],
  input[type='radio']:checked,
  input[type='radio']:not(:checked) {
    display: inline-block;
    position: relative;
    pointer-events: auto;
    opacity: 1;
    -ms-transform: scale(1.3); /* IE */
    -moz-transform: scale(1.3); /* FF */
    -webkit-transform: scale(1.3); /* Safari and Chrome */
    -o-transform: scale(1.3); /* Opera */
    transform: scale(1.3);
    background-color: #c30c25 !important;
  }
  color: #000;
  font-weight: 400;
}

.container-title-notification {
  margin-top: 82px !important;
  .title {
    margin-left: 0px;
    color: #000 !important;
  }
}
.updateImage_image_notification {
  display: block;
  margin: 62px auto !important;
}

.updateImage_delete_notification {
  position: relative;
  display: flex;
  top: -12px;
  right: 13px;
  border-radius: 50%;
  background: #c30c25;
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 2;
  float: right;

  .icon-delete {
    width: 20px;
    height: 20px;
    color: white;
    margin: 2px;
    font-weight: bold;
  }
}

.validate_notification_text {
  position: relative;
  bottom: 15px;
}

.loaderPositionNotification {
  position: relative;
  top: 30vh;
  right: 0vw;
}

.botonAddAllNotification {
  padding: 4px !important;
}
