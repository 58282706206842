.container-title {
  align-items: center;
  margin-bottom: 30px;
  i {
    font-size: 2rem;
    font-weight: 500;
    color: #c4c4c4;
  }

  .col-title {
    align-items: center;
    display: flex;
    .title {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: #6f6f6f;
      margin-bottom: 0px;
    }
  }

  .btn-close {
    border-radius: 50%;
    background: #c4c4c4;
    padding: 0px;
    float: right;
    cursor: pointer;

    .icon-close {
      align-content: center;
      vertical-align: middle;
      path {
        fill: white;
      }
    }
  }
}
