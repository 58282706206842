.row-logo-admin {
  margin-top: 80px;
}

.malls_prev_createOut {
  padding: 15px;
  background-color: #f0f0f0;
  border-radius: 40px;
  width: 100%;
}
.malls_prev_createIn {
  background-color: white;
  border-radius: 27px;
  margin: 0px;
  padding: 25px;
}
.malls_prev_createcol {
  height: 48px;
  padding: 0px;
  border-radius: 10px;
  background-color: $components-background;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.malls_prev_editOut {
  padding: 40px;
  background-color: #f0f0f0;
  border-radius: 40px;
  width: 100%;
}

.malls_prev_editIn {
  padding-bottom: 13px;
  border-bottom: 1px solid #e5e5e5;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}
.malls_prev_editrow {
  padding: 0px;
  border-radius: 29px;
}
.malls_prev_title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 37px;
  text-align: center;
  color: #565656;
  margin-bottom: 30px;
  span {
    font-weight: bolder;
  }
}
.malls_prev_logo {
  width: 300px;
}
.malls_prev_admin {
  width: 300px;
  margin: auto;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.7em;
  color: $site-primary-color;
  padding-left: 10px;
  margin-bottom: 20px;
}

.malls_prev_container {
  justify-content: space-around !important;
  margin-top: 40px;
  margin-bottom: 100px;
}

.malls_prev_button {
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 42px;
  color: black;
  padding: 3px 0px;
  span {
    margin: 0px;
    font-size: 1rem;
    font-weight: bold;
    color: black;
  }
}

.malls_prev_button:hover {
  cursor: pointer;
  background-color: #e3e3e3;
  border-radius: 10px;
}
